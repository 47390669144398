<template>
  <div id="ExchangeShare">
    <div
      class="content-card-container-column grid gap-2 grid-cols-1 sm:grid-cols-2"
    >
      <div class="content-card-columm">
        <ExchangeItem
          :exchange="data"
          :minimalView="true"
          class="sticky top-20"
        ></ExchangeItem>
      </div>

      <ModalTwoButton
        :headline="modalTwoButtonOptions.title"
        v-if="modalTwoButtonOptions.visible"
        @close="modalTwoButtonClose"
        @confirm="modalTwoButtonConfirm"
      ></ModalTwoButton>
      
      <div class="flex justify-center" v-if="data.shiftFromUser.status != 200">
        <NoDataBox headline="Kein Dienst vorhanden"><a class="link" href="https://app.stadtbahnfahrer.club/syncmanager" target="_newTab">Synchronisiere</a> diesen Dienst, um ihn zum Tausch anzubieten.<br /><router-link class="link" to="/tauschboerse">Zurück zur Tauschbörse</router-link></NoDataBox>
      </div>
      <div v-else>
          <div v-if="data.shiftFromUser.submission">

            <div class="alertbox border-color-brand" v-if="data.shiftFromUser.submission.status == 0">
              <div class="alertbox-icon bg-brand">
                <font-awesome-icon icon="info-circle" class="icon"/>
              </div>
              <div class="alertbox-content text-center">
                <p class="alertbox-heading text-blue-500">
                  <b>Warten auf Rückmeldung</b>
                </p>
                <p class="alertbox-maincontent">
                  Du hast Deinen Dienst zum Tausch angeboten.
                </p>
                <p class="alertbox-hint">
                  Wir benachrichtigen Dich, sobald Deine Anfrage beantwortet wurde.
                </p>
              </div>
            </div>

            <div class="alertbox border-green-600" v-if="data.shiftFromUser.submission.status == 1">
              <div class="alertbox-icon bg-green-600">
                <font-awesome-icon icon="info-circle" class="icon"/>
              </div>
              <div class="alertbox-content text-center">
                <p class="alertbox-heading text-green-500">
                  <b>Dienst getauscht</b>
                </p>
                <p class="alertbox-maincontent">
                  Mit {{data.shiftFromUser.submission.exchange.user.first_name}} {{data.shiftFromUser.submission.exchange.user.last_name}}<br />
                  <!-- 01.01.2021 - 00:00 Uhr -->
                </p>
                <p class="alertbox-hint">
                  Wir haben Deinen <a class="link" href="/dienstplan/monatsplan">Dienstplan</a> automatisch aktualisiert.
                </p>
              </div>
            </div>

            <div class="alertbox border-red-500" v-else-if="data.shiftFromUser.submission.status == 2">
              <div class="alertbox-icon bg-red-500">
                <font-awesome-icon icon="info-circle" class="icon"/>
              </div>
              <div class="alertbox-content">
                <p class="alertbox-heading text-red-400">
                  <b>Angebot abgelehnt</b>
                </p>
                <p class="alertbox-maincontent">
                  Dein Tauschangebot wurde abgelehnt.
                </p>
                <p class="alertbox-hint">
                  Dein Angebot wurde abgelehnt, weil die Anfrage abgelaufen, zurückgezogen oder abgelehnt wurde.
                </p>
              </div>
            </div>

            <div class="alertbox border-red-500" v-else-if="data.shiftFromUser.submission.status == 10">
              <div class="alertbox-icon bg-red-500">
                <font-awesome-icon icon="info-circle" class="icon"/>
              </div>
              <div class="alertbox-content">
                <p class="alertbox-heading text-red-400">
                 <b>Tausch abgebrochen</b>
                </p>
                <p class="alertbox-maincontent">
                  Der Tauschvorgang wurde aufgrund betriebstechnischen Gründen vorzeitig abgebrochen.
                </p>
              </div>
            </div>

            <div class="alertbox border-red-500" v-else-if="data.shiftFromUser.submission.status == 11">
              <div class="alertbox-icon bg-red-500">
                <font-awesome-icon icon="info-circle" class="icon"/>
              </div>
              <div class="alertbox-content">
                <p class="alertbox-heading text-red-400">
                 <b>Tausch abgebrochen</b>
                </p>
                <p class="alertbox-maincontent">
                  Der Tauschvorgang wurde aufgrund betriebstechnischen Gründen vorzeitig abgebrochen.
                </p>
              </div>
            </div>

            <div class="alertbox border-red-500" v-else-if="data.shiftFromUser.submission.status == 14">
              <div class="alertbox-icon bg-red-500">
                <font-awesome-icon icon="info-circle" class="icon"/>
              </div>
              <div class="alertbox-content">
                <p class="alertbox-heading text-red-400">
                 <b>Angebot abgelehnt</b>
                </p>
                <p class="alertbox-maincontent">
                  Dein Tauschangebot wurde abgelehnt.
                </p>
                <p class="alertbox-hint">
                  Dein Angebot wurde abgelehnt, weil die Anfrage abgelaufen, zurückgezogen oder abgelehnt wurde.
                </p>
              </div>
            </div>

            <div class="alertbox border-red-500" v-else-if="data.shiftFromUser.submission.status == 15">
              <div class="alertbox-icon bg-red-500">
                <font-awesome-icon icon="info-circle" class="icon"/>
              </div>
              <div class="alertbox-content">
                <p class="alertbox-heading text-red-400">
                 <b>Angebot abgelehnt</b>
                </p>
                <p class="alertbox-maincontent">
                  Dein Tauschangebot wurde abgelehnt.
                </p>
                <p class="alertbox-hint">
                  Dein Angebot wurde abgelehnt, weil die Anfrage abgelaufen, zurückgezogen oder abgelehnt wurde.
                </p>
              </div>
            </div>

            <!-- <div class="alertbox border-red-500" v-else-if="data.shiftFromUser.submission.status == 6">
              <div class="alertbox-icon bg-red-500">
                <font-awesome-icon icon="info-circle" class="icon"/>
              </div>
              <div class="alertbox-content">
                <p class="alertbox-heading text-red-400">
                  <b>Angebote abgelaufen</b>
                </p>
                <p class="alertbox-maincontent">
                  Der Tausch wurde nicht bis zum Dienstbeginn bestätigt und wird nicht mehr öffentlicht angezeigt.
                </p>
              </div>
            </div> -->

            <div class="alertbox border-color-brand" v-else-if="data.shiftFromUser.submission.status == 3">
              <div class="alertbox-icon bg-brand">
                <font-awesome-icon icon="info-circle" class="icon"/>
              </div>
              <div class="alertbox-content text-center">
                <p class="alertbox-heading text-blue-500">
                  <b>Warten auf Rückmeldung</b>
                </p>
                <p class="alertbox-maincontent">
                  Der Tauschvorgang wird bearbeitet.
                </p>
                <p class="alertbox-hint">
                  Wir benachrichtigen Dich, sobald sich der Status ändert.
                </p>
              </div>
            </div>

            <div class="alertbox border-yellow-600" v-else-if="data.shiftFromUser.submission.status == 4">
              <div class="alertbox-icon bg-yellow-600">
                <font-awesome-icon icon="info-circle" class="icon"/>
              </div>
              <div class="alertbox-content">
                <p class="alertbox-heading text-yellow-500">
                  <b>Tausch bestätigen!</b>
                </p>
                <p class="alertbox-maincontent">
                Kontaktiere die Fahrdienstleitung, um den Tausch zu bestätigen:<br><br>
                <b>{{ data.shiftFromUser.submission.exchange.dispatcher.name }}</b><br>
                <small>Tel.: {{ data.shiftFromUser.submission.exchange.dispatcher.phone }}</small>
                <br><br>
                Bestätige den Diensttausch am <b>{{getWeekdayForDate(data.shift.header_date)}}</b>, den <b>{{getFormatedDate(data.shift.header_date)}}</b>, mit dem Dienst <b><i>{{data.shift.header_nr}}</i></b> von <b>{{data.shiftFromUser.submission.exchange.user.first_name}} {{data.shiftFromUser.submission.exchange.user.last_name}}</b> (Personalnr. {{data.shiftFromUser.submission.exchange.user.servicecard_id}}) gegen Deinen Dienst <b><i>{{data.shiftFromUser.data.header_nr}}</i></b>. 
              </p>
              <p class="alertbox-hint">
                Bestätige den Tausch, sobald Du den Tausch mit der Fahrdienstleitung {{ data.shiftFromUser.submission.exchange.dispatcher.name }} besprochen hast.
              </p>
              </div>
            </div>

            <pre class="text-center text-gray-600 font-sans"><small>STATUS:{{data.shiftFromUser.submission.status}}</small></pre>

          </div>

         <div class="alertbox border-color-brand" v-else-if="exchangeInProgress">
            <div class="alertbox-icon bg-brand">
              <font-awesome-icon icon="info-circle" class="icon"/>
            </div>
            <div class="alertbox-content text-center">
              <p class="alertbox-heading text-blue-500">
                <b>Warten auf Rückmeldung</b>
              </p>
              <p class="alertbox-maincontent">
                Du hast Deinen Dienst zum Tausch angeboten.
              </p>
              <p class="alertbox-hint">
                Wir benachrichtigen Dich, sobald Deine Anfrage beantwortet wurde.
              </p>
            </div> 
          </div> 

        <!-- </div> -->


        <div v-if="data.shiftFromUser.wishFulfillment && !exchangeInProgress">
          <!-- <div v-else> -->
          <div class="alertbox border-color-brand">
            <div class="alertbox-icon bg-brand">
              <font-awesome-icon icon="info-circle" class="icon"/>
            </div>
            <div class="alertbox-content text-center">
              <p class="alertbox-heading text-blue-500">
                <b>Wunscherfüllung</b>
              </p>
              <div class="alertbox-maincontent">
                <div class="flex flex-wrap justify-center gap-3">
                  <p>Dienstlage <span v-if="data.shiftFromUser.wishFulfillment.times.length > 0" class="color-success">✓</span><span v-else class="color-danger">𐄂</span></p>
                  <p>Linie <span v-if="data.shiftFromUser.wishFulfillment.lines.length > 0" class="color-success">✓</span><span v-else class="color-danger">𐄂</span></p>
                  <p>Startort <span v-if="data.shiftFromUser.wishFulfillment.shiftstart.length > 0" class="color-success">✓</span><span v-else class="color-danger">𐄂</span></p>
                  <p>Endort <span v-if="data.shiftFromUser.wishFulfillment.shiftend.length > 0" class="color-success">✓</span><span v-else class="color-danger">𐄂</span></p>
                </div>
              </div>
              <p class="alertbox-hint" v-show="data.shiftFromUser.wishFulfillment.chance >= 75">
                Die Wünscherfüllung liegt bei mind. 75%. Gute Chancen, dass Dein Angebot angenommen wird!
              </p>
            </div>
          </div>
        </div>
        <ShiftBox :data="data.shiftFromUser.data" :minimalView="true" align="text-center"></ShiftBox>
        <div class="mt-3 flex gap-1">
          <button v-show="!exchangeInProgress" @click="exchangeOffer()" :disabled="isLoadingSubmitForExchange" class="card-button w-full text-center"><font-awesome-icon v-show="!isLoadingSubmitForExchange" icon="handshake" class="icon text-xl"/><font-awesome-icon v-show="isLoadingSubmitForExchange" class="icon text-xl" icon="spinner" pulse/><br>Dienst <b>verbindlich</b> anbieten</button>
        
          <button v-if="data.shiftFromUser.submission && data.shiftFromUser.submission.status == 4" @click="exchangeMarkAsComplete(data.shiftFromUser.submission)" class="card-button w-full text-center" :disabled="isLoadingDispatch">Bestätigen</button>
          <button
                  v-if="data.shiftFromUser.submission && data.shiftFromUser.submission.status == 4"
                  @click="exchangeMarkAsFailure(data.shiftFromUser.submission)"
                  class="
                    card-button-designless
                    bg-red-800
                    text-center text-white
                  "
                  :disabled="isLoadingDispatch"
                >
                  Problem melden
                </button>
          <!-- <button v-if="data.shiftFromUser.submission.status == 4" @click="offerMarkAsFailure(submission)" class="card-button-designless bg-red-800 text-center text-white" :disabled="isLoadingDispatch">Problem melden</button> -->
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import NoDataBox from "@/components/NoDataBox";
import ServiceSituationIcon from "@/components/ServiceSituationIcon";
import ShiftDetails from "@/components/ShiftDetail";
import ShiftBox from "@/components/ShiftBox";
import ExchangeItem from "@/components/ExchangeItem";
import ModalTwoButton from "@/components/ModalTwoButton";

import { mapGetters } from "vuex";

export default {
  name: "ExchangeDetails",
  components: {
    NoDataBox,
    ServiceSituationIcon,
    ShiftDetails,
    ShiftBox,
    ExchangeItem,
    ModalTwoButton
  },
  props: ["data"],
  data() {
    return {
      exchangeInProgress: false,
      isLoadingSubmitForExchange: false,
      isLoadingDispatch: false,
      modalTwoButtonOptions: {
        visible: false,
        title: null,
        action: -1,
      },
    };
  },
  methods: {
    modalTwoButtonClose() {
      this.modalTwoButtonOptions = {
        visible: false,
        title: null,
        action: -1
      }
    },
    modalTwoButtonConfirm() {
      var action = this.modalTwoButtonOptions.action;
      this.modalTwoButtonClose()
 
      if(action == 3) {
        this.submitOffer();
      } else if(action == 4) {
        //offerMarkAsComplete
        this.isLoadingDispatch = true;

        this.$store
        .dispatch("exchangeSubmissionCheckout", {submissionId: this.submissionSelectedData.id })
        .then((a) => {

          if (a.status) {
            this.data.exchange_submissions[0].status = 4;
          } else {
            alert('Es ist ein Fehler aufgetreten... Versuche es bitte später erneut. Schreibe uns gerne, falls Du Hilfe brauchst.');
          }

          this.isLoadingDispatch = false;
        });
      } else if(action == 5) {
        //offerMarkAsFailure
        this.isLoadingDispatch = true;

        this.$store
        .dispatch("exchangeSubmissionFailure", {submissionId: this.submissionSelectedData.id })
        .then((a) => {

          if (a.status) {
            
          } else {
            alert('Es ist ein Fehler aufgetreten... Versuche es bitte später erneut. Schreibe uns gerne, falls Du Hilfe brauchst.');
          }

          this.isLoadingDispatch = false;
        });
      } else if (action == 6) {
        //exchangeMarkAsComplete
        this.isLoadingDispatch = true;

        this.$store
          .dispatch("exchangeSubmissionExchangeCheckout", {
            submissionId: this.submissionSelectedData.id,
          })
          .then((a) => {

            if (a.status) {
              this.data.shiftFromUser.submission.status = 1;
            } else {
              alert(
                "Es ist ein Fehler aufgetreten... Versuche es bitte später erneut. Schreibe uns gerne, falls Du Hilfe brauchst."
              );
            }

            this.isLoadingDispatch = false;
          });
      } else if (action == 7) {
        //exchangeMarkAsFailure
        this.isLoadingDispatch = true;

        this.$store
          .dispatch("exchangeSubmissionExchangeFailure", {
            submissionId: this.submissionSelectedData.id,
          })
          .then((a) => {

            if (a.status) {
              this.data.exchange_submissions[0].status = 11;
            } else {
              alert(
                "Es ist ein Fehler aufgetreten... Versuche es bitte später erneut. Schreibe uns gerne, falls Du Hilfe brauchst."
              );
            }

            this.isLoadingDispatch = false;
          });
      }
    },
    exchangeOffer() {
      
      this.modalTwoButtonOptions.title = "Dienst verbindlich anbieten";
      this.modalTwoButtonOptions.visible = true;
      this.modalTwoButtonOptions.action = 3;
    },
    exchangeMarkAsFailure(data) {
      this.submissionSelectedData = data;

      alert(
        "Achtung: mit dieser Aktion wird der Tauschvorgang vorzeitig abgebrochen! Nutze diese Funktion nur, wenn es betriebstechnische Gründe (z.B. Unterschreitung der Ruhezeit, fehlende Anforderungen, etc.) gibt, die es nicht erlauben den Tauschvorgang seitens der Fahrdienstleitung abzuwickeln. Beachte bitte unsere Nutzungsbedinungen."
      );

      this.modalTwoButtonOptions.title = "Problem melden & Tausch abbrechen";
      this.modalTwoButtonOptions.visible = true;
      this.modalTwoButtonOptions.action = 7;
    },
    exchangeMarkAsComplete(data) {
      this.submissionSelectedData = data;

      this.modalTwoButtonOptions.title = "Tausch bestätigen";
      this.modalTwoButtonOptions.visible = true;
      this.modalTwoButtonOptions.action = 6;
    },
    submitOffer() {
      this.isLoadingSubmitForExchange = true
      this.$store
      .dispatch("addShiftToExchange", {exchangeId: this.data.id, shiftId: this.data.shiftFromUser.data.id })
      .then((a) => {
        if (a.status) {
          this.exchangeInProgress = true
        } else {
          alert('Es ist ein Fehler bei der Erstellung aufgetreten... Versuche es bitte später erneut. Schreibe uns gerne, falls Du Hilfe brauchst.');
        }

        this.isLoadingSubmitForExchange = false;
      });
    }
  },
  mounted() {
    if(this.data.shiftFromUser.status == 200 && typeof this.data.shiftFromUser.data.exchange_submissions !== "undefined") {
      for (let index = 0; index < this.data.shiftFromUser.data.exchange_submissions.length; index++) {
        const element = this.data.shiftFromUser.data.exchange_submissions[index];
        if(element.exchange == this.data.id) {
          this.exchangeInProgress = true
          break;
        }
      }
    }
  },
  created() {},
  computed: {
    ...mapGetters({
      user: "getUser",
      getWeekdayForDate: "getWeekdayForDate",
      getFormatedDate: "getFormatedDate",
      getServiceSituationIcon: "getServiceSituationIcon",
    }),
  },
};
</script>

