<template>
  <div id="modalRegistrationRecommendCompany" class="modal">
    <div class="modal-background"></div>
    <!-- Modal content -->
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h2>{{ headline }}</h2>
          <span class="close" @click="sendActionCancel">&times;</span>
        </div>
        <div class="modal-body">
          <p class="text-center font-medium mb-3">Bestätige bitte die Aktion</p>
          <div class="text-center flex flex-col gap-6">
            <button
              @click="sendActionConfirm()"
              class="button-filled text-center btn-fullsize w-full"
            >
              Bestätigen
            </button>

            <button
              @click="sendActionCancel()"
              class="button-designless text-center btn-fullsize w-full"
            >
              Abbrechen
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalTwoButton",
  data() {
    return {};
  },
  props: {
    headline: String,
  },
  methods: {
    sendActionConfirm() {
      this.$emit("confirm");
    },
    sendActionCancel() {
      this.$emit("close");
    },
  },
};
</script>




