<template>
  <div id="ExchangeShare">
    <TheHeader></TheHeader>
    <SiteNav></SiteNav>
    <div class="px-3 py-3 bg-lines bg-cover bg-fixed bg-center">
      <div v-if="user" class="md:container md:mx-auto">

        <div v-if="data">

          <h1 class="brand-color-dark">Details <span v-show="!data.exchangeFromUser">zum</span><span v-show="data.exchangeFromUser">zu Deinem</span> Diensttausch</h1>
          <p><router-link to="/tauschboerse"><font-awesome-icon icon="arrow-left" class="icon"/> Zur Tauschbörse</router-link></p>

          
          <DetailsOffer v-if="data.exchangeFromUser" :data="data"></DetailsOffer>

          <DetailsInterested v-if="!data.exchangeFromUser" :data="data"></DetailsInterested>


          <div class="text-center text-gray-600 font-sans" v-if="data">
            <p><small>EXCHANGE-ID: {{data.id}}</small></p>
          </div>
        </div>
        <div v-else>
          <div v-if="isLoading">Daten werden geladen...</div>
          <div v-else>
            <NoDataBox headline="Tausch nicht gefunden"
              >Dieser Diensttausch ist nicht (mehr) verfügbar.<br /><router-link
                class="link"
                to="/tauschboerse"
                >Zurück zur Tauschbörse</router-link
              ></NoDataBox
            >
          </div>
        </div>
      </div>

      <div v-else class="md:container md:mx-auto text-center">
        <h1 class="brand-color-dark">Diensttausch</h1>

        <div v-if="data">

          <p class="font-medium">Du siehst einen Diensttausch, der über den StadtbahnfahrerClub angeboten wird.</p>

          <p>Als Nutzer:in vom StadtbahnfahrerClub kannst Du Deinen Dienst sekundenschnell gegen diesen Dienst tauschen.</p>

          <!-- <pre>{{data}}</pre> -->
          <div class="md:w-2/3 lg:w-1/3 w-auto mx-auto space-y-2 my-2">
            <ExchangeItem
            :exchange="data"
            :minimalView="true"
            class=""
            ></ExchangeItem>

            <button
            class="button-filled text-center btn-fullsize w-full"
            @click="goToLogin()"
            >
              Anmelden & Dienst tauschen
            </button>
          </div>

          <hr class="my-4">

          <h2 class="brand-color-dark text-lg">Noch nicht im Club?</h2>
          <p class="mb-2">Der StadtbahnfahrerClub ist exklusiv für Stadtbahnfahrer:in.<br>
          Trete kostenfrei bei, damit wir auch Deinen Arbeitsalltag optimieren können.</p>

          <a href="https://stadtbahnfahrer.club"
            class="card-button card-button-designless text-center btn-fullsize w-full md:w-2/3 lg:w-1/3  block mx-auto"
          >
            Mehr erfahren
          </a>

          <div class="text-center text-gray-600 font-sans">
            <p><small>EXCHANGE-ID: 123456789</small></p>
          </div>
          
        </div>
        <div v-else>
          <div v-if="isLoading">Daten werden geladen...</div>
          <div v-else>
            <NoDataBox headline="Tausch nicht gefunden"
              >Dieser Diensttausch ist nicht (mehr) verfügbar.</NoDataBox
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import TheHeader from "@/components/TheHeader";
import SiteNav from "@/components/TheNavigation";

import ExchangeItem from "@/components/ExchangeItem";

import NoDataBox from "@/components/NoDataBox";
import ServiceSituationIcon from "@/components/ServiceSituationIcon";
import ShiftDetails from "@/components/ShiftDetail";
import ShiftBox from "@/components/ShiftBox";

import ModalTwoButton from "@/components/ModalTwoButton";

import DetailsOffer from "@/components/ExchangeDetailsOffer";
import DetailsInterested from "@/components/ExchangeDetailsInterested";

import { mapGetters } from "vuex";

export default {
  name: "ExchangeDetails",
  components: {
    TheHeader,
    SiteNav,
    NoDataBox,
    DetailsOffer,
    DetailsInterested,
    ExchangeItem
  },
  data() {
    return {
      data: null,
      isLoading: true,
     }
  },
  methods: {
    goToLogin() {
      this.$store.state.redirectedFrom = this.$router.currentRoute.fullPath
      this.$router.push('/anmelden')
    }
  },
  mounted() {
    this.$store.dispatch("fetchUserProfile");

    this.$store
      .dispatch("fetchExchangeDetails", this.$route.params.id)
      .then((a) => {
        if (a.result.status == 200) {
          this.data = a.result.response;
        }

        this.isLoading = false;
      });
  },
  created() {},
  computed: {
    ...mapGetters({
      user: "getUser"
    }),
  },
};
</script>

