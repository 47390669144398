<template>
  <div id="ExchangeShare">
    <div
      class="content-card-container-column grid gap-2 grid-cols-1 sm:grid-cols-2"
    >
      <div class="content-card-columm">
        <ExchangeItem
          :exchange="data"
          :minimalView="true"
          class="sticky top-20"
        ></ExchangeItem>
      </div>

      <ModalTwoButton
        :headline="modalTwoButtonOptions.title"
        v-if="modalTwoButtonOptions.visible"
        @close="modalTwoButtonClose"
        @confirm="modalTwoButtonConfirm"
      ></ModalTwoButton>

      <div class="content-card-columm">
        <div v-if="data.exchange_submissions.length <= 0">
          <NoDataBox headline="Keine Angebote"
            >Du hast keine offenen Tauschangebote für diesen Dienst.<br />Wir
            benachrichtigen Dich, sobald sich hier etwas ändert.<br /><br />Du
            kannst den Dienst direkt mit Kolleginnen & Kollegen teilen:<br /><b
              ><i class="select-all"
                >https://app.stadtbahnfahrer.club/dienst-tauschen/{{
                  data.id
                }}</i
              ></b
            ><br><br>Oder sende selber Deinen Dienst einem <router-link to="/tauschboerse/verfuegbare-dienste" class="link">offenen Tausch</router-link> als Angebot.</NoDataBox
          >
        </div>

        <div v-else>
          <div v-show="!data.exchangeInProgress" class="text-center">
            <h4 class="text-lg font-bold">
              {{ data.exchange_submissions.length }} Angebote
            </h4>
            <p>Wähle ein Tauschangebot aus...</p>
          </div>

          <div v-if="data.exchangeInProgress">
            <div
              class="alertbox border-green-600"
              v-if="data.exchange_submissions[0].status == 1"
            >
              <div class="alertbox-icon bg-green-600">
                <font-awesome-icon icon="info-circle" class="icon" />
              </div>
              <div class="alertbox-content text-center">
                <p class="alertbox-heading text-green-500">
                  <b>Dienst getauscht</b>
                </p>
                <p class="alertbox-maincontent">
                  Mit {{ data.exchange_submissions[0].user.first_name }}
                  {{ data.exchange_submissions[0].user.last_name }}<br />
                  <!-- 01.01.2021 - 00:00 Uhr -->
                </p>
                <p class="alertbox-hint">
                  Wir haben Deinen
                  <a class="link" href="/dienstplan/monatsplan">Dienstplan</a>
                  automatisch aktualisiert.
                </p>
              </div>
            </div>

            <div
              class="alertbox border-yellow-600"
              v-else-if="data.exchange_submissions[0].status == 3"
            >
              <div class="alertbox-icon bg-yellow-600">
                <font-awesome-icon icon="info-circle" class="icon" />
              </div>
              <div class="alertbox-content">
                <p class="alertbox-heading text-yellow-500">
                  <b>Tausch durchführen!</b>
                </p>
                <p class="alertbox-maincontent">
                  Kontaktiere die Fahrdienstleitung, um den Tausch
                  durchzuführen:<br />
                  Tausche Deinen Dienst
                  <b
                    ><i>{{ data.shift.header_nr }}</i></b
                  >
                  am <b>{{ getWeekdayForDate(data.shift.header_date) }}</b
                  >, den <b>{{ getFormatedDate(data.shift.header_date) }}</b
                  >, mit dem Dienst
                  <b
                    ><i>{{ data.exchange_submissions[0].shift.header_nr }}</i></b
                  >
                  von
                  <b
                    >{{ data.exchange_submissions[0].user.first_name }}
                    {{ data.exchange_submissions[0].user.last_name }}</b
                  >
                  (Personalnr.
                  {{ data.exchange_submissions[0].user.servicecard_id }}).
                </p>
                <p class="alertbox-hint">
                  Bestätige den Tausch, sobald Du den Tausch mit der
                  Fahrdienstleitung besprochen hast.
                </p>
              </div>
            </div>

            <div
              class="alertbox border-color-brand"
              v-else-if="data.exchange_submissions[0].status == 4"
            >
              <div class="alertbox-icon bg-brand">
                <font-awesome-icon icon="info-circle" class="icon" />
              </div>
              <div class="alertbox-content text-center">
                <p class="alertbox-heading text-blue-500">
                  <b>Warten auf Rückmeldung</b>
                </p>
                <p class="alertbox-maincontent">
                  Der Tauschvorgang wird bearbeitet.
                </p>
                <p class="alertbox-hint">
                  Wir benachrichtigen Dich, sobald sich der Status ändert.
                </p>
              </div>
            </div>

            <div class="alertbox border-red-500" v-else-if="data.exchange_submissions[0].status == 10">
              <div class="alertbox-icon bg-red-500">
                <font-awesome-icon icon="info-circle" class="icon"/>
              </div>
              <div class="alertbox-content">
                <p class="alertbox-heading text-red-400">
                 <b>Tausch abgebrochen</b>
                </p>
                <p class="alertbox-maincontent">
                  Der Tauschvorgang wurde vorzeitig abgebrochen.
                </p>
                <p class="alertbox-hint">
                  Kontaktiere uns bitte, um das Tauschangebot erneut zu veröffentlichen.
                </p>
              </div>
            </div>
          </div>

          <div
            class="grid gap-x-4 gap-y-6 grid-cols-1"
          >
            <div v-for="submission in data.exchange_submissions" :key="submission.id">
              <div v-if="submission.wishFulfillment && !data.exchangeInProgress">
                <div class="alertbox border-color-brand">
                  <div class="alertbox-icon bg-brand">
                    <font-awesome-icon icon="info-circle" class="icon" />
                  </div>
                  <div class="alertbox-content text-center">
                    <p class="alertbox-heading text-blue-500">
                      <b>Wunscherfüllung</b>
                    </p>
                    <div class="alertbox-maincontent">
                      <div class="flex flex-wrap justify-center gap-3">
                        <p>
                          Dienstlage
                          <span
                            v-if="submission.wishFulfillment.times.length > 0"
                            class="color-success"
                            >✓</span
                          ><span v-else class="color-danger">𐄂</span>
                        </p>
                        <p>
                          Linie
                          <span
                            v-if="submission.wishFulfillment.lines.length > 0"
                            class="color-success"
                            >✓</span
                          ><span v-else class="color-danger">𐄂</span>
                        </p>
                        <p>
                          Startort
                          <span
                            v-if="
                              submission.wishFulfillment.shiftstart.length > 0
                            "
                            class="color-success"
                            >✓</span
                          ><span v-else class="color-danger">𐄂</span>
                        </p>
                        <p>
                          Endort
                          <span
                            v-if="
                              submission.wishFulfillment.shiftend.length > 0
                            "
                            class="color-success"
                            >✓</span
                          ><span v-else class="color-danger">𐄂</span>
                        </p>
                      </div>
                    </div>
                    <p
                      class="alertbox-hint"
                      v-show="submission.wishFulfillment.chance >= 75"
                    >
                      Deine Wünscherfüllung liegt bei mind. 75%.
                    </p>
                  </div>
                </div>
              </div>

             <ShiftBox
                :data="submission.shift"
                :minimalView="true"
                align="text-center"
              ></ShiftBox>
              <div class="flex gap-1">
                <button
                  v-if="submission.status == 0"
                  @click="offerAccept(submission)"
                  class="card-button w-full text-center"
                  :disabled="isLoadingDispatch"
                >
                  <font-awesome-icon
                    icon="handshake"
                    class="icon text-xl"
                  /><br />Dienst <b>verbindlich</b> auswählen
                </button>
                <button
                  v-if="submission.status == 0"
                  @click="offerDecline(submission)"
                  class="
                    card-button-designless
                    bg-red-800
                    text-center text-white
                  "
                  :disabled="isLoadingDispatch"
                >
                  <p class="px-3">
                    <font-awesome-icon icon="times" class="icon text-xl" />
                  </p>
                </button>




                <div class="dropdown card-button w-full text-center" v-if="submission.status == 3" :disabled="isLoadingDispatch">
                  <button @click="openDropdownButton()" class="button-dropdown w-full h-full font-bold">Bestätigen...</button>
                  <div id="myDropdown" class="button-dropdown-content">
                    <a class="cursor-pointer" @click="offerMarkAsComplete(submission, dispatch)" v-for="dispatch in dispatcher" :key="dispatch.id">{{dispatch.name}}<br><small>Tel.: {{dispatch.phone}}</small></a>
                  </div>
                </div>

                <!-- <button
                  v-if="submission.status == 3"
                  @click="offerMarkAsComplete(submission)"
                  class="card-button w-full text-center"
                  :disabled="isLoadingDispatch"
                >
                  Bestätigen
                </button> -->




                <button
                  v-if="submission.status == 3"
                  @click="offerMarkAsFailure(submission)"
                  class="
                    card-button-designless
                    bg-red-800
                    text-center text-white
                  "
                  :disabled="isLoadingDispatch"
                >
                  Problem melden
                </button>
              </div>
              <div class="text-center text-gray-600 font-sans" v-if="data">
                <p>
                  <small>SUBMISSION-ID: {{ submission.id }}</small>
                </p>
                <!-- <pre><small>{{submission}}</small></pre> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    
    <!-- <div v-if="data.exchange_submissions.length <= 0">
      <div class="text-center">
        <h4 class="text-lg font-bold">Angebote aus der Tauschbörse <pre>(PREVIEW)</pre></h4>
        <p>Wähle einen offenen Dienst aus der Tauschbörse aus</p>
      </div>

      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-6 ">
        <div>
          <ExchangeItem
            :exchange="data"
            :minimalView="true"
            align="text-center"
          ></ExchangeItem>
          <button
          
            :disabled="isLoadingSubmitForExchange"
            class="card-button w-full text-center"
          >
            <font-awesome-icon
              v-show="!isLoadingSubmitForExchange"
              icon="handshake"
              class="icon text-xl"
            /><font-awesome-icon
              v-show="isLoadingSubmitForExchange"
              class="icon text-xl"
              icon="spinner"
              pulse
            /><br />Anfrage <b>verbindlich</b> stellen
          </button>
        </div>
      </div>
    </div> -->
  </div>
</template>


<script>
import TheHeader from "@/components/TheHeader";
import SiteNav from "@/components/TheNavigation";

import NoDataBox from "@/components/NoDataBox";
import ServiceSituationIcon from "@/components/ServiceSituationIcon";
import ShiftDetails from "@/components/ShiftDetail";
import ShiftBox from "@/components/ShiftBox";
import ExchangeItem from "@/components/ExchangeItem";
import ModalTwoButton from "@/components/ModalTwoButton";

import { mapGetters } from "vuex";

export default {
  name: "ExchangeDetails",
  components: {
    TheHeader,
    SiteNav,
    NoDataBox,
    ServiceSituationIcon,
    ShiftDetails,
    ShiftBox,
    ExchangeItem,
    ModalTwoButton,
  },
  props: ['data'],
  data() {
    return {
      // data: null,
      // data: JSON.parse(this.d),
      isLoading: true,
      isLoadingSubmitForExchange: false,
      isLoadingDispatch: false,
      exchangeInProgress: false,
      exchangeSubmissions: [],
      exchangeOffers: [
        {
          id: "18b4502c-1430-461d-bba9-ea7ec523761c",
          header_break: -1,
          header_nr: "7508",
          header_service: 1,
          shift_paidtime: "08:10",
          shift_workingtime: "08:40",
          time_start: "16:06",
          time_end: "00:46+",
          typ: 1,
          date: "2022-02-23T00:00:00",
          header_date: "2022-02-23T00:00:00",
          duty_time: "bc07fa2a-8625-4e0f-9f6b-372d3639d2ad",
          shift: [
            {
              id: "36eea6bf-f0ce-40f8-8afc-04af2396a1ee",
              sort: 0,
              line: "12",
              cycle: "17",
              start_location: "RDP",
              start_time: "16:06",
              start_direction: "ZSF",
              end_location: "RDP",
              end_time: "20:25",
              end_direction: "MEK",
              typ: 0,
            },
            {
              id: "1cc1a14f-5a6e-4939-bd18-98c30264aa07",
              sort: 1,
              line: null,
              cycle: null,
              start_location: "S - RDP",
              start_time: "20:42",
              start_direction: null,
              end_location: "S - RDP",
              end_time: "21:12",
              end_direction: null,
              typ: 1,
            },
            {
              id: "c4005115-1e5c-4329-be78-48cb9489e4bf",
              sort: 2,
              line: "15",
              cycle: null,
              start_location: "RDP",
              start_time: "21:17",
              start_direction: null,
              end_location: "FSP",
              end_time: "21:19",
              end_direction: null,
              typ: 2,
            },
            {
              id: "c6892614-a32d-4866-a4be-55639da8d317",
              sort: 3,
              line: "12",
              cycle: "12",
              start_location: "FSP",
              start_time: "21:38",
              start_direction: "ZSF",
              end_location: "MRH-S",
              end_time: "00:40",
              end_direction: "",
              typ: 0,
            },
          ],
        },
      ],
      submissionSelectedData: null,
      dispatcherSelectedData: null,
      dispatcher: [],
      modalTwoButtonOptions: {
        visible: false,
        title: null,
        action: -1,
      },
    };
  },
  methods: {
    openDropdownButton() {
      document.getElementById("myDropdown").classList.toggle("show");
    },
    modalTwoButtonClose() {
      this.modalTwoButtonOptions = {
        visible: false,
        title: null,
        action: -1,
      };
    },
    modalTwoButtonConfirm() {
      var action = this.modalTwoButtonOptions.action;
      this.modalTwoButtonClose();

      if (action == 1) {
        //Accept
        this.isLoadingDispatch = true;

        this.fetchDispatcher()

        this.$store
          .dispatch("exchangeSubmissionAccept", {
            submissionId: this.submissionSelectedData.id,
          })
          .then((a) => {
            if (a.status) {
              for (
                let index = 0;
                index < this.data.exchange_submissions.length;
                index++
              ) {
                if (
                  this.data.exchange_submissions[index] !==
                  this.submissionSelectedData
                ) {
                  this.data.exchange_submissions.splice(index, 1);
                }
              }

              var s = this.data.exchange_submissions.findIndex(
                (s) => s === this.submissionSelectedData
              );

              if (s != -1) {
                // this.exchangeSubmissions[s].shift.user.first_name = a.result.first_name
                // this.exchangeSubmissions[s].shift.user.last_name = a.result.last_name
                // this.exchangeSubmissions[s].shift.user.servicecard_id = a.result.servicecard_id

                this.data.exchange_submissions[s].user = {
                  first_name: a.result.response.first_name,
                  last_name: a.result.response.last_name,
                  servicecard_id: a.result.response.servicecard_id,
                };

                this.data.exchange_submissions[0].user = {
                  first_name: a.result.response.first_name,
                  last_name: a.result.response.last_name,
                  servicecard_id: a.result.response.servicecard_id,
                };

                this.data.exchangeInProgress = true;
                this.data.exchange_submissions[0].status = 3;
              } else {
                window.location.reload();
              }
            } else {
              alert(
                "Es ist ein Fehler aufgetreten... Versuche es bitte später erneut. Schreibe uns gerne, falls Du Hilfe brauchst."
              );
            }

            this.isLoadingDispatch = false;
          });
      } else if (action == 2) {
        //Decline
        this.isLoadingDispatch = true;

        this.$store
          .dispatch("exchangeSubmissionDecline", {
            submissionId: this.submissionSelectedData.id,
          })
          .then((a) => {
            if (a.status) {
              var s = this.data.exchange_submissions.findIndex(
                (s) => s === this.submissionSelectedData
              );
              this.data.exchange_submissions.splice(s, 1);
            } else {
              alert(
                "Es ist ein Fehler aufgetreten... Versuche es bitte später erneut. Schreibe uns gerne, falls Du Hilfe brauchst."
              );
            }

            this.isLoadingDispatch = false;
          });
      } else if (action == 4) {
        //offerMarkAsComplete
        this.isLoadingDispatch = true;

        this.$store
          .dispatch("exchangeSubmissionCheckout", {
            submissionId: this.submissionSelectedData.id,
            dispatcherId: this.dispatcherSelectedData.id,
            exchangeId: this.data.id
          })
          .then((a) => {
            console.log(a);

            if (a.status && a.result.status == 200) {
              this.data.exchange_submissions[0].status = 4;
            } else {
              alert(
                "Es ist ein Fehler aufgetreten... Versuche es bitte später erneut. Schreibe uns gerne, falls Du Hilfe brauchst."
              );
            }

            this.isLoadingDispatch = false;
          });
      } else if (action == 5) {
        //offerMarkAsFailure
        this.isLoadingDispatch = true;

        this.$store
          .dispatch("exchangeSubmissionFailure", {
            submissionId: this.submissionSelectedData.id,
          })
          .then((a) => {
            if (a.status) {
            } else {
              alert(
                "Es ist ein Fehler aufgetreten... Versuche es bitte später erneut. Schreibe uns gerne, falls Du Hilfe brauchst."
              );
            }

            this.isLoadingDispatch = false;
          });
      }


      return

      if (action == 1) {
        //Accept
        this.isLoadingDispatch = true;

        this.$store
          .dispatch("exchangeSubmissionAccept", {
            submissionId: this.submissionSelectedData.id,
          })
          .then((a) => {
            console.log(a);

            if (a.status) {
              for (
                let index = 0;
                index < this.exchangeSubmissions.length;
                index++
              ) {
                if (
                  this.exchangeSubmissions[index] !==
                  this.submissionSelectedData
                ) {
                  this.exchangeSubmissions.splice(index, 1);
                }
              }

              var s = this.exchangeSubmissions.findIndex(
                (s) => s === this.submissionSelectedData
              );

              console.log("a");
              console.log(a);
              console.log(s);

              if (s != -1) {
                // this.exchangeSubmissions[s].shift.user.first_name = a.result.first_name
                // this.exchangeSubmissions[s].shift.user.last_name = a.result.last_name
                // this.exchangeSubmissions[s].shift.user.servicecard_id = a.result.servicecard_id

                this.exchangeSubmissions[s].user = {
                  first_name: a.result.response.first_name,
                  last_name: a.result.response.last_name,
                  servicecard_id: a.result.response.servicecard_id,
                };

                this.exchangeSubmissions[0].user = {
                  first_name: a.result.response.first_name,
                  last_name: a.result.response.last_name,
                  servicecard_id: a.result.response.servicecard_id,
                };

                this.data.exchangeInProgress = true;
                this.data.exchange_submissions[0].status = 3;
              } else {
                window.location.reload();
              }
            } else {
              alert(
                "Es ist ein Fehler aufgetreten... Versuche es bitte später erneut. Schreibe uns gerne, falls Du Hilfe brauchst."
              );
            }

            this.isLoadingDispatch = false;
          });
      } else if (action == 2) {
        //Decline
        this.isLoadingDispatch = true;

        this.$store
          .dispatch("exchangeSubmissionDecline", {
            submissionId: this.submissionSelectedData.id,
          })
          .then((a) => {
            console.log(a);

            if (a.status) {
              // alert("Success");

              var s = this.exchangeSubmissions.findIndex(
                (s) => s === this.submissionSelectedData
              );
              this.exchangeSubmissions.splice(s, 1);
            } else {
              alert(
                "Es ist ein Fehler aufgetreten... Versuche es bitte später erneut. Schreibe uns gerne, falls Du Hilfe brauchst."
              );
            }

            this.isLoadingDispatch = false;
          });
      } else if (action == 3) {
        this.submitExchange();
      } else if (action == 4) {
        //offerMarkAsComplete
        this.isLoadingDispatch = true;

        this.$store
          .dispatch("exchangeSubmissionCheckout", {
            submissionId: this.submissionSelectedData.id,
          })
          .then((a) => {
            console.log(a);

            if (a.status) {
              this.data.exchange_submissions[0].status = 4;
            } else {
              alert(
                "Es ist ein Fehler aufgetreten... Versuche es bitte später erneut. Schreibe uns gerne, falls Du Hilfe brauchst."
              );
            }

            this.isLoadingDispatch = false;
          });
      } else if (action == 5) {
        //offerMarkAsFailure
        this.isLoadingDispatch = true;

        this.$store
          .dispatch("exchangeSubmissionFailure", {
            submissionId: this.submissionSelectedData.id,
          })
          .then((a) => {
            console.log(a);

            if (a.status) {
            } else {
              alert(
                "Es ist ein Fehler aufgetreten... Versuche es bitte später erneut. Schreibe uns gerne, falls Du Hilfe brauchst."
              );
            }

            this.isLoadingDispatch = false;
          });
      } else if (action == 6) {
        //exchangeMarkAsComplete
        this.isLoadingDispatch = true;

        this.$store
          .dispatch("exchangeSubmissionExchangeCheckout", {
            submissionId: this.submissionSelectedData.id,
          })
          .then((a) => {
            console.log(a);

            if (a.status) {
              this.data.shiftFromUser.submission.status = 1;
            } else {
              alert(
                "Es ist ein Fehler aufgetreten... Versuche es bitte später erneut. Schreibe uns gerne, falls Du Hilfe brauchst."
              );
            }

            this.isLoadingDispatch = false;
          });
      }
    },
    offerMarkAsFailure(data) {
      this.submissionSelectedData = data;

      alert(
        "Achtung: mit dieser Aktion wird der Tauschvorgang vorzeitig abgebrochen! Nutze diese Funktion nur, wenn es betriebstechnische Gründe (z.B. Unterschreitung der Ruhezeit, fehlende Anforderungen, etc.) gibt, die es nicht erlauben den Tauschvorgang seitens der Fahrdienstleitung abzuwickeln. Beachte bitte unsere Nutzungsbedinungen."
      );

      this.modalTwoButtonOptions.title = "Problem melden & Tausch abbrechen";
      this.modalTwoButtonOptions.visible = true;
      this.modalTwoButtonOptions.action = 5;
    },
    offerMarkAsComplete(data, dispatcher) {
      this.submissionSelectedData = data;
      this.dispatcherSelectedData = dispatcher

      this.modalTwoButtonOptions.title = "Tausch bestätigen";
      this.modalTwoButtonOptions.visible = true;
      this.modalTwoButtonOptions.action = 4;
    },
    exchangeMarkAsComplete(data) {
      this.submissionSelectedData = data;

      this.modalTwoButtonOptions.title = "Tausch bestätigen";
      this.modalTwoButtonOptions.visible = true;
      this.modalTwoButtonOptions.action = 6;
    },
    offerAccept(data) {
      this.submissionSelectedData = data;

      this.modalTwoButtonOptions.title = "Tauschangebot verbindlich annehmen";
      this.modalTwoButtonOptions.visible = true;
      this.modalTwoButtonOptions.action = 1;
    },
    offerDecline(data) {
      this.submissionSelectedData = data;

      this.modalTwoButtonOptions.title = "Tauschangebot ablehen";
      this.modalTwoButtonOptions.visible = true;
      this.modalTwoButtonOptions.action = 2;
    },
    submitExchange() {
      this.isLoadingSubmitForExchange = true;
      this.$store
        .dispatch("addShiftToExchange", {
          exchangeId: this.data.id,
          shiftId: this.data.shiftFromUser.data.id,
        })
        .then((a) => {
          if (a.status) {
            this.exchangeInProgress = true;
          } else {
            alert(
              "Es ist ein Fehler bei der Erstellung aufgetreten... Versuche es bitte später erneut. Schreibe uns gerne, falls Du Hilfe brauchst."
            );
          }

          this.isLoadingSubmitForExchange = false;
        });
    },
    fetchDispatcher() {
      this.$store
        .dispatch("fetchDispatcher")
        .then((a) => {
          if (a.status) {
            this.dispatcher = a.result.data;
          } else {
            alert(
              "Es ist ein Fehler beim Laden der Informationen der Fahrdienstleitung aufgetreten... Versuche es bitte später erneut. Schreibe uns gerne, falls Du Hilfe brauchst."
            );
          }
        });
    }
  },
  mounted() {
    window.onclick = function(event) {
      if (!event.target.matches('.button-dropdown')) {
        var dropdowns = document.getElementsByClassName("button-dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains('show')) {
            openDropdown.classList.remove('show');
          }
        }
      }
    }

    if(this.data.exchange_submissions.length == 1 && this.data.exchange_submissions[0].status == 3) {
      this.fetchDispatcher()
    }
  },
  created() {},
  computed: {
    ...mapGetters({
      user: "getUser",
      getWeekdayForDate: "getWeekdayForDate",
      getFormatedDate: "getFormatedDate",
      getServiceSituationIcon: "getServiceSituationIcon",
    }),
  },
};
</script>

